import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import "./plugins/element.js";
import "./assets/style/global.index.scss";
import 'lib-flexible/flexible'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
