/**
 * webRTC config file
 *
 * @authors Luo-jinghui (luojinghui424@gmail.com)
 * @date  2020-01-17 12:04:01
 */
export const THIRD = true;

export const SERVER = {
  wssServer: "wss://cloudapi.xylink.com",
  httpServer: "https://cloudapi.xylink.com",
  baseServer: "https://cloud.xylink.com",
  logServer: "https://log.xylink.com",
};

export const ACCOUNT = {
  extId: "218896f2206a6cd7a358ab112f4e6fae2e450c40",
  clientId: "Gwgt5eV1G9kkl5MVKocc7nTh",
  clientSecret: "zfdRFP3hJ7aUsd683Kl8ytrcR21PH3cA",
};
