import Vue from 'vue'
import VueRouter from 'vue-router'
// import Welcome from '@/components/UserLogin/welcome'
import Login from '@/components/UserLogin/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  // {
  //   path: '/welcome',
  //   name: 'welcome',
  //   component: Welcome
  // },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

export default router
